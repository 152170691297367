import React from 'react';
import { isFilled } from '../condition.utils';

/**
 * MultipleSelection menu
 * @returns {XML}
 */
class DropDown extends React.Component {

    onChange = e => {
        const value = e.target.value;
        this.props.onChange(this.props.id, value);
    };

    renderOption = option => {
        return (
            <option
                className={`${this.props.className}__option`}
                value={option[this.props.valueProp]}
                key={option[this.props.valueProp]}
            >{option[ this.props.labelProp ]}{this.props.suffix}</option>
        );
    };

    render() {
        if ( !isFilled(this.props.options) ){
            return null;
        }
        const currentValue = this.props.value || this.props.options[ 0 ][ this.props.valueProp ];

        return (
            <select className={this.props.className}
                    onChange={this.onChange}
                    value={currentValue}
            >
                {this.props.options.map(this.renderOption)}
            </select>
        );
    }
}

const noCallback = () => {
};

DropDown.defaultProps = {
    options : [],
    className : 'dropDown',
    onChange : noCallback,
    id : undefined,
    value : 0,
    valueProp : 'value',
    labelProp : 'label',
    suffix: ''
};

export default DropDown;