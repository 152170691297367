import React from 'react';

class ClickOutside extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.addEventListener('click',
                                  this.handleClickOutside,
                                  true);
    }

    componentWillUnmount() {
        document.removeEventListener('click',
                                     this.handleClickOutside,
                                     true);
    }

    handleClickOutside = (event) => {
        if ( !this.domNode || !this.domNode.contains(event.target) || this.shouldIgnoreTarget(
            event.target) ){
            this.props.onClickOutside();
        }
    };

    setNode = domNode => {
        console.log(domNode);
        this.domNode = domNode;
    };

    shouldIgnoreTarget(target) {
        return this.props.excludedClasses && this.props.excludedClasses.indexOf(
            target.className) > -1;
    }

    render() {
        return (
            <div
                ref={this.setNode}
                className={this.props.className}
            >
                {this.props.children}
            </div>
        );
    }
}

export default ClickOutside;