// helper functions for conditions

/**
 * Check if target exists
 * @param target
 * @returns {boolean}
 */
const existy = target => target != null;

const defined = target => target !== undefined;

/**
 * Check if the condition is truthy
 * @param condition
 * @returns {boolean}
 */
const truthy = condition => (condition !== false) && existy(condition);

const isFilled = target => existy(target) && target.length > 0;

const hasKeys = target => Object.keys(target).length > 0;

/**
 * Execute a function conditionally
 * @param action The function that could be executed
 * @param parameters The parameters for the function
 * @returns {{now: (function(): *), if: (function(): undefined), ifOne:
 *     (function(): undefined), ifAll: (function(): undefined)}}
 */
const execute = (action, ...parameters) => {
    let options = {
        now : () => action.apply(this, parameters),
        if : cond => (truthy(cond)) ? options.now() : undefined,
        ifOne : conditions => conditions.findIndex(
            cond => truthy(cond)) > -1 ? options.now() : undefined,
        ifAll : conditions => conditions.findIndex(
            cond => !truthy(cond)) > -1 ? undefined : options.now()

    };
    return options;
};

export { existy, defined, truthy, execute, isFilled, hasKeys };