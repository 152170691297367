import React from 'react';

const autoScaler = ComposedComponent => class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            scale: 1
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    componentDidMount() {
        this.resize();
    }

    resize = () => {
        const {clientWidth, clientHeight, scrollWidth, scrollHeight} = this.container;
        const scaleX = clientWidth / scrollWidth;
        const scaleY = clientHeight / scrollHeight;

        const scale = Math.min(scaleX, scaleY);

        this.setState({scale : scaleX});
    };

    render() {
        const transform = `scale(${this.state.scale})`;

        const style = {
            transform: transform,
            transformOrigin: 'top left'
        };

        return (
            <div
                className="autoScaler"
                style={style}
                ref={container => this.container = container}
            >
                <ComposedComponent {...this.props} />
            </div>
        );
    }
};


export default autoScaler;